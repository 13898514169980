import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import __c from "../utilities/combineClasses"
import ActionBlock from "./action-block"

import style from "./benefits-block.module.scss"

export default ({ className }) => {
  return (
    <ActionBlock
      className={__c([style.component, className])}
      title="Learn more..."
      ctaLink="/sign-up"
      ctaText="Sign up for free"
      theme="dark"
    >
      <ul className={__c(style.list)}>
        <StaticQuery
          query={graphql`
            {
              allFile(filter: { sourceInstanceName: { eq: "benefits" } }) {
                edges {
                  node {
                    id
                    childMdx {
                      frontmatter {
                        title
                      }
                      slug
                    }
                  }
                }
              }
            }
          `}
          render={data =>
            data.allFile.edges.map(({ node }) => (
              <li key={node.id} className={__c(style.listItem)}>
                <Link
                  to={`/${node.childMdx.slug}`}
                  className={__c(style.listLink)}
                  activeClassName={__c(style.listLinkActive)}
                >
                  {node.childMdx.frontmatter.title}
                </Link>
              </li>
            ))
          }
        ></StaticQuery>
      </ul>
    </ActionBlock>
  )
}
