import React from "react"
import { Link } from "gatsby"
import __c from "../utilities/combineClasses"

import style from "./action-block.module.scss"

export default ({ children, className, title, ctaLink, ctaText, theme }) => {
  return (
    <aside
      className={__c([style.component, className])}
      data-theme={theme ? theme : `default`}
    >
      {title ? (
        <header className={__c(style.header)}>
          <h1 className={__c(style.title)}>{title}</h1>
        </header>
      ) : (
        false
      )}
      <main className={__c(style.main)}>{children}</main>
      {ctaText && ctaLink ? (
        <footer className={__c(style.footer)}>
          <Link className={__c([`aside-button`, style.cta])} to={ctaLink}>
            {ctaText}
          </Link>
        </footer>
      ) : (
        false
      )}
    </aside>
  )
}
