import React from "react"
import __c from "../utilities/combineClasses"

import style from "./benefit-blurb.module.scss"

export default ({ children, title, icon, iconOffset }) => {
  return (
    <section className={__c(style.component)}>
      {title || icon ? (
        <header className={__c(style.header)}>
          {title ? <h1 className={__c(style.title)}>{title}</h1> : false}
          {icon ? (
            <div className={__c(style.icon)}>
              <div
                style={{
                  backgroundImage: `url('${icon}')`,
                  backgroundPositionX: `calc(64px * ${iconOffset})`,
                }}
              />
            </div>
          ) : (
            false
          )}
        </header>
      ) : (
        false
      )}
      <main className={__c(style.main)}>{children}</main>
    </section>
  )
}
